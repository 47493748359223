import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  Button,
  ContentLayout,
  Header,
  Link,
  SideNavigation,
  SpaceBetween,
  Table,
  TopNavigation
} from '@cloudscape-design/components';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Incident } from '../../../shared/interfaces';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient, ScanCommand } from '@aws-sdk/lib-dynamodb';
import logo from './assets/logo512.png';
import moment from 'moment';
import '@cloudscape-design/global-styles/index.css';

const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [incidents, setIncidents] = useState<Incident[]>([]);

  const fetchIncidents = async () => {
    setIsLoading(true);
    const auth = await fetchAuthSession();
    const client = new DynamoDBClient({
      region: 'us-east-1',
      credentials: auth.credentials
    });
    const docClient = DynamoDBDocumentClient.from(client);
    const results = await docClient.send(new ScanCommand({ TableName: 'IMSTable' }));
    setIncidents((results.Items as Incident[]) ?? []);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchIncidents();
  }, []);

  return (
    <Router>
      <TopNavigation
        identity={{
          href: '/',
          title: 'Incident Management System',
          logo: {
            src: logo,
            alt: 'logo'
          }
        }}
        utilities={[
          {
            type: 'button',
            text: 'Go to FMS',
            href: 'https://fms.brucehow.people.aws.dev/',
            external: true,
            externalIconAriaLabel: ' (opens in a new tab)'
          },
          {
            type: 'button',
            iconName: 'notification',
            title: 'Notifications',
            ariaLabel: 'Notifications (unread)',
            badge: true,
            disableUtilityCollapse: false
          },
          {
            type: 'menu-dropdown',
            text: 'User A',
            description: 'userA@example.com',
            iconName: 'user-profile',
            items: [
              { id: 'profile', text: 'Profile' },
              { id: 'preferences', text: 'Preferences' },
              { id: 'security', text: 'Security' },
              { id: 'signout', text: 'Sign out' }
            ]
          }
        ]}
      />
      <AppLayout
        toolsHide={true}
        navigation={
          <SideNavigation
            activeHref="/"
            items={[
              {
                type: 'section-group',
                title: 'Incidents',
                items: [
                  {
                    type: 'link',
                    text: 'Manage Incidents',
                    href: '/'
                  },
                  {
                    type: 'link',
                    text: 'Source Code',
                    external: true,
                    href: 'https://gitlab.aws.dev/aws-wwps-anz-proserve/vline/fms-ims-integration/'
                  }
                ]
              }
            ]}
          />
        }
        content={
          <ContentLayout>
            <Routes>
              <Route path="*" element={<Navigate replace to="/" />} />
              <Route
                path="/"
                element={
                  <SpaceBetween direction="vertical" size="m">
                    <BreadcrumbGroup
                      items={[
                        { text: 'Incidents', href: '/' },
                        { text: 'Manage Incidents', href: '/' }
                      ]}
                    />
                    <Header description="Effectively handle and resolve incidents with streamlined processes and tools">
                      Manage Incidents
                    </Header>

                    <Table
                      header={
                        <Header
                          actions={<Button iconName="refresh" onClick={async () => await fetchIncidents()} />}
                          counter={isLoading ? '' : `(${incidents.length})`}
                        >
                          Incidents
                        </Header>
                      }
                      wrapLines
                      columnDefinitions={[
                        {
                          id: 'pk',
                          header: 'Event Id',
                          cell: (item) => <Link>{item.pk}</Link>,
                          width: 330
                        },
                        {
                          id: 'description',
                          header: 'Description',
                          cell: (item) => item.description
                        },
                        {
                          id: 'entity',
                          header: 'Entity',
                          cell: (item) => item.entity,
                          width: 100
                        },
                        {
                          id: 'source',
                          header: 'Source',
                          cell: () => <>FMS</>,
                          width: 100
                        },

                        {
                          id: 'reported',
                          header: 'Reported',
                          cell: (item) => {
                            const formattedDate = moment(item.reported).local().format('MM/DD/YYYY hh:mm:ssa');
                            return <>{formattedDate}</>;
                          }
                        },
                        {
                          id: 'received',
                          header: 'Received',
                          cell: (item) => {
                            const formattedDate = moment(item.received).local().format('MM/DD/YYYY hh:mm:ssa');
                            return <>{formattedDate}</>;
                          }
                        }
                      ]}
                      resizableColumns
                      loading={isLoading}
                      items={incidents}
                      loadingText="Loading incidents"
                      trackBy="pk"
                      empty={
                        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                          <SpaceBetween size="m">
                            <b>No incidents found</b>
                          </SpaceBetween>
                        </Box>
                      }
                    />
                  </SpaceBetween>
                }
              />
            </Routes>
          </ContentLayout>
        }
      />
    </Router>
  );
};

export default App;
